<template>
  <v-container>
    <v-row class="d-flex justify-start mt-0">
      <v-col cols="12" lg="3">
        <v-expansion-panels v-model="panel.filtro">
          <v-expansion-panel readonly>
            <v-expansion-panel-header class="py-0 px-4 text-h5 blue-grey darken-1 " style="cursor: default">
              <div class="white--text">
                <v-icon class="mb-1 white--text" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel.filtro = panel.filtro === 1 ? 0 : 1">
                  <v-icon class="white--text">fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1 pb-2">
              <v-row class="pt-4">
                <v-expansion-panels v-model="panel.marca">
                  <v-expansion-panel class="rounded-0">

                    <v-expansion-panel-header>Marcas</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-for="(item) in marcasFiltradas" :key="item.id">
                        <v-checkbox :label="item.nombre" :value="item" color="#808080" item-color="#808080" dense
                          return-object v-model="filtro.marcas" hide-details></v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                 <!-- Modelo -->
                 <v-expansion-panels v-model="panel.modelo">
                  <v-expansion-panel class="rounded-0">
                    <v-expansion-panel-header>Modelo</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-for="item in modelosFiltrados" :key="item">
                        <v-checkbox :label="item" :value="item" color="#808080" item-color="#808080" dense
                          return-object v-model="filtro.modelo" hide-details></v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <!-- AÑO -->
                <v-expansion-panels v-model="panel.anio">
                  <v-expansion-panel class="rounded-0">

                    <v-expansion-panel-header>Año</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-for="(item) in aniosFiltrados" :key="item">
                        <v-checkbox :label="item.toString()" :value="item" color="#808080" item-color="#808080" dense
                          return-object v-model="filtro.anio" hide-details></v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <!-- TRANSMISION -->
                <v-expansion-panels v-model="panel.transmision">
                  <v-expansion-panel class="rounded-0">

                    <v-expansion-panel-header>Transmisión</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-for="(item) in transmisionFiltrada" :key="item">
                        <v-checkbox :label="item" :value="item" color="#808080" item-color="#808080" dense
                          v-model="filtro.transmision" hide-details></v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>


                <!-- MOTOR -->
                <v-expansion-panels v-model="panel.motor">
                  <v-expansion-panel class="rounded-0">

                    <v-expansion-panel-header>Motor</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-for="(item) in motorFiltrado" :key="item">
                        <v-checkbox :label="item" :value="item" color="#808080" item-color="#808080" dense
                          v-model="filtro.motor" hide-details></v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>


                <!-- COMBUSTIBLE -->
                <v-expansion-panels v-model="panel.combustible">
                  <v-expansion-panel class="rounded-0">

                    <v-expansion-panel-header>Combustible</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-for="(item) in combustibleFiltrado" :key="item">
                        <v-checkbox :label="item" :value="item" color="#808080" item-color="#808080" dense
                          v-model="filtro.combustible" hide-details></v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <!-- Precio -->
                <v-expansion-panels v-model="panel.precio">
                  <v-expansion-panel class="rounded-0">

                    <v-expansion-panel-header>Precio</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-for="(item, index) in preciosUsados" :key="index">
                        <v-checkbox class="pb-1" :label="item.nombre" :value="item" color="#808080" item-color="#808080"
                          dense v-model="filtro.precio" hide-details></v-checkbox>
                      </div>
                      <v-col>
                        <v-row class="pt-3">
                          <TextFieldMoney :label="minimo" v-model="filtro.precioMin" />
                        </v-row>
                        <v-row>
                          <TextFieldMoney :label="maximo" v-model="filtro.precioMax" />
                        </v-row>
                      </v-col>
                    </v-expansion-panel-content>

                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" lg="9">
        <v-autocomplete v-model="ordenSeleccionado" :items="orderBy" label="Ordenar por" dense outlined
          class=""></v-autocomplete>
        <v-row>
          <v-col cols="11">
            <v-sheet>
              <v-chip-group mandatory style="background-color:#F5F5F5;">
                <div v-if="filtro.marcas.length > 0">
                  <v-chip small v-for="(item, index) in filtro.marcas" :key="index" close
                    @click:close="eliminarChip(filtro.marcas, index)">
                    {{ item.nombre }}
                  </v-chip>
                </div>

                <div v-if="filtro.modelo.length > 0">
                  <v-chip @click:close="eliminarChip(filtro.modelo, index)" small
                    v-for="(item, index) in filtro.modelo" :key="index" close>
                    {{ item }}
                  </v-chip>
                </div>

                <div v-if="filtro.anio.length > 0">
                  <v-chip @click:close="eliminarChip(filtro.anio, index)" small v-for="(item, index) in filtro.anio"
                    :key="index" close>
                    {{ item }}
                  </v-chip>
                </div>

                <div v-if="filtro.transmision.length > 0">
                  <v-chip @click:close="eliminarChip(filtro.transmision, index)" small
                    v-for="(item, index) in filtro.transmision" :key="index" close>
                    {{ item }}
                  </v-chip>
                </div>
                <div v-if="filtro.motor.length > 0">
                  <v-chip @click:close="eliminarChip(filtro.motor, index)" small v-for="(item, index) in filtro.motor"
                    :key="index" close>
                    {{ item }}
                  </v-chip>
                </div>
                <div v-if="filtro.combustible.length > 0">
                  <v-chip @click:close="eliminarChip(filtro.combustible, index)" small
                    v-for="(item, index) in filtro.combustible" :key="index" close>
                    {{ item }}
                  </v-chip>
                </div>
                <div v-if="filtro.precio.length > 0">
                  <v-chip @click:close="eliminarChip(filtro.precio, index)" small v-for="(item, index) in filtro.precio"
                    :key="index" close>
                    {{ item.nombre }}
                  </v-chip>
                </div>
              </v-chip-group>
            </v-sheet>


          </v-col>
          <v-col cols="1" class="d-flex justify-center align-center">
            <v-btn title="Limpiar filtro" color="orange" class="mr-3 " lg="justify-end" icon @click="limpiarFiltro">
              <v-icon>fas fa-broom</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-alert v-if="vehiculosFiltrados.length === 0" class="mx-auto mt-4" max-width="600" type="warning" border="left"
          dense text>
          No hay vehiculos disponibles
        </v-alert>
        <CardsVehiculos :vehiculos="vehiculosFiltrados" />
      </v-col>

    </v-row>

  </v-container>
</template>

<script>
import { format_money_round } from "../util/utils"
import TextFieldMoney from './util/TextFieldMoney.vue'
import CardsVehiculos from "./usados/CardsVehiculos.vue"

export default {
  data() {
    return {
      panel: {
        filtro: 0,
        marca: 0,
        modelo: 1,
        anio: 1,
        transmision: 1,
        motor: 1,
        combustible: 1,
        precio: 1
      },
      filtro: {
        marcas: [],
        modelo: [],
        anio: [],
        transmision: [],
        motor: [],
        combustible: [],
        precio: [],
        precioMin: null,
        precioMax: null
      },
      ordenSeleccionado: '',
      vehiculos: [],
      marcas: [],
      empresas: [],
      minimo: 'Mínimo',
      maximo: 'Máximo',
      orderBy: ['Precio: Mayor - Menor', 'Precio: Menor - Mayor', 'Marca: A -Z', 'Marca: Z -A', 'Modelo: A-Z', 'Modelo: Z-A',
        'Año: Menor - Mayor', 'Año: Mayor-Menor', 'Sin orden específico'],
      vehiculosFiltrados: [],
      vehiculosSeteados: [],
      marcasFiltradas: [],
      marcasSeteadas: [],
      modelosSeteados: [],
      modelosFiltrados: [],
      aniosSeteados: [],
      aniosFiltrados: [],
      transmisionSeteada: [],
      transmisionFiltrada: [],
      motorSeteado: [],
      motorFiltrado: [],
      combustibleSeteado: [],
      combustibleFiltrado: [],
      preciosUsados: [],
      widthViewport: null
    }
  },

  created() {
    this.initFiltroChecks()
    // window.addEventListener('resize', this.getViewportSize);
    this.getViewportSize()
  },

  beforeDestroy() {
    // window.removeEventListener('resize', this.getViewportSize);
  },

  props: {
    loader: Boolean
  },


  methods: {

    getViewportSize() {
      this.widthViewport = window.innerWidth;
      this.widthViewport >= 768 ? this.panel.filtro = 0 : this.panel.filtro = 1
    },

    async initFiltroChecks() {
      await this.getVehiculosUsados()
      this.$emit("update:loader", false)
    },

    async getVehiculosUsados() {
      this.$store.state.loading = true
      await this.$store.dispatch('usados/getVehiculosUsados').then(res => {
        if (res.exito == 1) {
          this.vehiculos = res.data
        }
      }).then(() => {
        // Llamadas a las funciones después de obtener los vehículos
        this.getPrecios();
        this.setAnios();
        this.setModelos();
        this.setTransmision();
        this.setMotor();
        this.setCombustible();
      }).catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        });
      });

      await this.getMarcas()
      this.vehiculosSeteados = this.vehiculos.map(vehiculo => {
        // Encuentro la marca que tiene el vehículo comparando la id de la marca y del marcaRef provenientes de firebase
        const marcaCorrespondientePorVehiculo = this.marcas.find(marca => marca.id === vehiculo.marcaRef.id);

        return {
          ...vehiculo,
          marca: marcaCorrespondientePorVehiculo.nombre
        };
      });

      // Guardo para luego filtrar en los detalles de cada vehiculo.
      localStorage.setItem('vehiculos', JSON.stringify(this.vehiculosSeteados))

      this.vehiculosFiltrados = this.vehiculosSeteados
      this.$store.state.loading = false
    },

    async getMarcas() {
      this.$store.state.loading = true
      await this.$store.dispatch('usados/getMarcasUsados').then(res => {
        if (res.exito == 1) {
          this.marcas = res.data
        }
      }).catch((error) => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })

      this.setMarcas()
      this.marcasFiltradas = this.marcasSeteadas
      this.$store.state.loading = false

    },

    setMarcas() {
      const marcasIds = this.vehiculos.map(vehiculo => vehiculo.marcaRef.id);
      // Retorno también, las marcas ordenadas alfabeticamente
      this.marcasSeteadas = this.marcas.filter(marca => marcasIds.includes(marca.id))
        .sort((a, b) => a.nombre.localeCompare(b.nombre));
    },

    setModelos(){
      this.modelosSeteados = Array.from(new Set(this.vehiculos.map(vehiculo => vehiculo.modeloBase))).sort((a, b) => a.localeCompare(b))

      this.modelosSeteados = this.modelosSeteados.filter(modelo => modelo != '')
    },

    setAnios() {
      // creo un Set de los años que estan incluidos en el array de vehiculos
      const aniosSet = Array.from(new Set(this.vehiculos.map(item => item.anio)));

      // ordeno las fechas
      this.aniosSeteados = aniosSet.sort((a, b) => b - a);
      this.aniosFiltrados = this.aniosSeteados
    },

    setTransmision() {
      this.transmisionSeteada = Array.from(new Set(this.vehiculos.map(vehiculo => vehiculo.transmision)))
      this.transmisionSeteada = this.transmisionSeteada.filter(transmision => transmision != null)

      this.transmisionFiltrada = this.transmisionSeteada
    },
    
    setMotor() {
      this.motorSeteado = Array.from(new Set(this.vehiculos.map(vehiculo => vehiculo.motor).filter(motor => motor != null))).sort((a, b) => a.localeCompare(b))
      this.motorFiltrado = this.motorSeteado
    },

    setCombustible() {
      this.combustibleSeteado = Array.from(new Set(this.vehiculos.map(vehiculo => vehiculo.combustible).filter(combustible => combustible != null))).sort((a, b) => a.localeCompare(b))
      this.combustibleFiltrado = this.combustibleSeteado
    },

    handleResize() {
      this.filtro.panel = this.calcularFiltroPanel();
    },


    async getPrecios() {
      await this.$store.dispatch('usados/getPrecios').then(res => {
        this.precio = res.data;
      }).catch(error => {
      });

      const primerItemIntervalo = this.precio.desde + this.precio.intervalo;
      const segundoItemIntervalo = primerItemIntervalo + this.precio.intervalo;
      this.preciosUsados = [
        {
          nombre: `Hasta ${format_money_round(this.precio.desde)}`,
          value: "Primer intervalo",
          intervalo: [this.precio.desde]
        },
        {
          nombre: `Desde ${format_money_round(this.precio.desde)} hasta ${format_money_round(primerItemIntervalo)}`,
          value: "Segundo intervalo",
          intervalo: [this.precio.desde, primerItemIntervalo]
        },
        {
          nombre: `Desde ${format_money_round(primerItemIntervalo)} hasta ${format_money_round(segundoItemIntervalo)}`,
          value: "Tercer intervalo",
          intervalo: [primerItemIntervalo, segundoItemIntervalo]
        },
        {
          nombre: `Mas de ${format_money_round(this.precio.hasta)}`,
          value: "Cuarto intervalo",
          intervalo: [this.precio.hasta]
        }
      ];

    },


    limpiarFiltro() {
      this.filtro = {
        marcas: [],
        modelo: [],
        anio: [],
        transmision: [],
        motor: [],
        combustible: [],
        precio: [],
        precioMin: null,
        precioMax: null
      }
      this.ordenSeleccionado = ''
    },

    eliminarChip(array, index) {
      array.splice(index, 1);
    },
  },

  watch: {

    filtroPanel(newFiltro) {
      this.filtro.panel = newFiltro;
    },
    // Filtro ordenar por
    ordenSeleccionado: {
      handler(nuevoValor) {
        switch (nuevoValor) {
          case 'Precio: Mayor - Menor':
            this.vehiculosFiltrados = this.vehiculosFiltrados.sort((a, b) => b.precio - a.precio);
            break;
          case 'Precio: Menor - Mayor':
            this.vehiculosFiltrados = this.vehiculosFiltrados.sort((a, b) => a.precio - b.precio);
            break;
          case 'Marca: A -Z':
            this.vehiculosFiltrados = this.vehiculosFiltrados.sort((a, b) => a.marca.localeCompare(b.marca));
            break;
          case 'Marca: Z -A':
            this.vehiculosFiltrados = this.vehiculosFiltrados.sort((a, b) => b.marca.localeCompare(a.marca));
            break;
          case 'Modelo: A-Z':
            this.vehiculosFiltrados = this.vehiculosFiltrados.sort((a, b) => a.modelo.localeCompare(b.modelo));
            break;
          case 'Modelo: Z-A':
            this.vehiculosFiltrados = this.vehiculosFiltrados.sort((a, b) => b.modelo.localeCompare(a.modelo));
            break;
          case 'Año: Menor - Mayor':
            this.vehiculosFiltrados = this.vehiculosFiltrados.sort((a, b) => a.anio - b.anio);
            break;
          case 'Año: Mayor-Menor':
            this.vehiculosFiltrados = this.vehiculosFiltrados.sort((a, b) => b.anio - a.anio);
            break;
          default:
            // Por defecto, no se realiza ningún filtro
            break;
        }
      },
      deep: true
    },

    // Filtro lateral
    filtro: {
      handler(nuevoValor) {
        // Recupero los valores del objeto
        const { marcas, modelo,  anio, transmision, motor, combustible, precio, precioMin, precioMax } = nuevoValor;

        // Función para verificar si un filtro está vacío
        const filtroVacio = filtro => filtro.length === 0 || filtro.every(item => item === null);

        // Si no hay ningún filtro activo, mostrar todos los vehículos
        if (filtroVacio(marcas) && filtroVacio(modelo) && filtroVacio(anio) &&
          filtroVacio(transmision) && filtroVacio(motor) && filtroVacio(combustible) &&
          filtroVacio(precio) && precioMin === null && precioMax === null) {
          this.vehiculosFiltrados = this.vehiculosSeteados;
          this.marcasFiltradas = this.marcasSeteadas
          return;
        }

        // Aplicar filtros activos
        this.vehiculosFiltrados = this.vehiculosSeteados.filter(vehiculo => {
          return (filtroVacio(marcas) || marcas.some(marca => marca.nombre === vehiculo.marca)) &&
            (filtroVacio(modelo) || modelo.some(mod => mod === vehiculo.modeloBase)) &&
            (filtroVacio(anio) || anio.includes(vehiculo.anio)) &&
            (filtroVacio(transmision) || transmision.includes(vehiculo.transmision)) &&
            (filtroVacio(motor) || motor.includes(vehiculo.motor)) &&
            (filtroVacio(combustible) || combustible.includes(vehiculo.combustible)) &&
            (
              filtroVacio(precio) || precio.some(intervalo => {
                if (intervalo.intervalo.length === 1) {
                  if (intervalo.value == 'Cuarto intervalo') {
                    return vehiculo.precio >= intervalo.intervalo[0]
                  }
                  return vehiculo.precio <= intervalo.intervalo[0];
                } else {
                  return vehiculo.precio >= intervalo.intervalo[0] && vehiculo.precio < intervalo.intervalo[1];
                }
              })
            )
            &&
            (precioMin === null || vehiculo.precio >= precioMin) &&
            (precioMax === null || vehiculo.precio <= precioMax);
        });
      },
      deep: true
    },

    vehiculosFiltrados(val) {
      const marcasVehiculosFiltrado = Array.from(new Set(val.map(vehiculo => vehiculo.marca)))
      const modelosVehiculosFiltrado = Array.from(new Set(val.map(vehiculo => vehiculo.modeloBase)))
      const aniosVehiculosFiltrado = Array.from(new Set(val.map(vehiculo => vehiculo.anio)))
      const transmisionesVehiculosFiltrado = Array.from(new Set(val.map(vehiculo => vehiculo.transmision)))
      const motoresVehiculosFiltrado = Array.from(new Set(val.map(vehiculo => vehiculo.motor)))
      const combustiblesVehiculosFiltrado = Array.from(new Set(val.map(vehiculo => vehiculo.combustible)))

      if (this.filtro.marcas.length == 0) {
        this.marcasFiltradas = this.marcasSeteadas.filter(marca => marcasVehiculosFiltrado.includes(marca.nombre))
      }

      if (this.filtro.modelo.length == 0) {
        this.modelosFiltrados = this.modelosSeteados.filter(modelo => modelosVehiculosFiltrado.includes(modelo))
      }

      if (this.filtro.anio.length == 0) {
        this.aniosFiltrados = this.aniosSeteados.filter(anio => aniosVehiculosFiltrado.includes(anio))
      }

      if (this.filtro.transmision == 0) {
        this.transmisionFiltrada = this.transmisionSeteada.filter(transmision => transmisionesVehiculosFiltrado.includes(transmision))
      }

      if (this.filtro.motor == 0) {
        this.motorFiltrado = this.motorSeteado.filter(motor => motoresVehiculosFiltrado.includes(motor))
      }

      if (this.filtro.combustible == 0) {
        this.combustibleFiltrado = this.combustibleSeteado.filter(combustible => combustiblesVehiculosFiltrado.includes(combustible))
      }

    },

    windowWidth(newWidth) {
      this.panel.filtro = newWidth < 768 ? 1 : 0;
    }
  },
  components:
    { TextFieldMoney, CardsVehiculos }
}
</script>
