<template>
  <v-container>
          <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Contacto</h2>

          <v-responsive
            class="mx-auto mb-12"
            width="56"
          >
            <v-divider class="mb-1"></v-divider>

            <v-divider></v-divider>
          </v-responsive>

          <v-theme-provider light>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-text-field
                  flat
                  solo
                  v-model="nombre"
                  placeholder="Nombre"
                  dense
                  :rules="[rules.requiredNombre]"
                  style="margin-top:-25px"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  flat
                  solo
                  v-model="apellido"
                  placeholder="Apellido"
                  dense
                  :rules="[rules.requiredApellido]"
                  style="margin-top:-25px"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  flat
                  type="email"
                  solo
                  v-model="email"
                  placeholder="Email"
                  dense
                  :rules="[rules.requiredEmail , rules.email]"
                  style="margin-top:-25px"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                
                <v-text-field
                  flat
                  solo
                  v-model="telefono"
                  placeholder="Telefono"
                  dense
                  :rules="[rules.requiredTelefono]"
                  style="margin-top:-25px"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
            
                <v-textarea
                  flat
                  placeholder="Mensaje"
                  solo
                  v-model="mensaje"
                  height="90px"
                  :rules="[rules.requiredMensaje]"
                  style="margin-top:-25px"
                ></v-textarea>
              </v-col>
              <v-col
                    class="mx-auto"
                    cols="12"
                    v-if="alert === true"
             >
                    <v-alert 
                    :value="alert" 
                    type="warning"
                    setinerval
                    text
                    >
                        No se pude enviar el mensaje , hay campos vacios.
                    </v-alert>
              </v-col>
              <v-col
                    class="mx-auto"
                    cols="12"
                    v-if="alert_success === true"
             >
                    <v-alert 
                    :value="alert_success" 
                    type="success"
                    text
                    >
                        El mensaje fue enviado con exito.
                    </v-alert>
              </v-col>
              <v-col
                class="mx-auto"
                cols="auto"
              >
              
                <v-btn
                  color="primary"
                  :loading="loading"
                  @click="Enviar()"
                  style="margin-top:-25px;"
                >
                  Enviar
                </v-btn>
              </v-col>
              
            </v-row>
            
          </v-theme-provider>
        </v-container>
</template>

<script>
import { db ,  fbTime} from '../firebase'
import moment from 'moment'

export default {
    data() {
        return {
            nombre:'',
            apellido:'',
            email:'',
            telefono:'',
            mensaje:'',
            alert : false,
            alert_success:false,
            loading:false,
            rules : {
              requiredNombre : value => !! value || 'Nombre Obligatorio',
              requiredApellido : value => !! value || 'Apellido Obligatorio',
              requiredEmail : value => !! value || 'Email Obligatorio',
              requiredTelefono : value => !! value || 'Telefono Obligatorio',
              requiredMensaje : value => !! value || 'Mensaje Obligatorio',
              email: value => {
                  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  return pattern.test(value) || 'Email Invalido'
              }
            }
        }
    },

    methods: {

        validar(){
            if(!this.nombre || !this.apellido || !this.email || !this.telefono || !this.mensaje){
                this.alert = true
                this.loading = false
                return false
            }
            else{
                this.alert = false
                return true
            }
        },

        async Enviar(){
            // await fetch('', {

            // })
            this.loading = true
            if(this.validar()){
                let mensaje = {
                    nombre:this.nombre,
                    email:this.email,
                    apellido:this.apellido,
                    telefono:this.telefono,
                    estado:'Pendiente',
                    comentario:this.mensaje,
                    observacion:'',
                    fecha:fbTime.fromDate(new Date()),
                    proviene:'Dycar'
                }
                db.collection('mensajes').add(mensaje)
                .then(() =>{
                    this.loading = false
                    this.alert_success = true
                })
            }
        }
    },
}
</script>

<style>

</style>